/* eslint-disable @next/next/no-img-element */
import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as cartAnimation from 'public/animations/cart.json'
import { useShoppingList } from '@functions/hooks'
import { changeModal, setAddIndicatorsList, setGoodToAdd } from '@redux/actions'
import { clx, analytics } from '@utils'

import ChoseMarketForm from '@components/common/forms/chose_market_form'
import ChangeMarketForm from '@components/common/forms/change_user_form'
import Cart from '@components/cart'
import Loading from '@components/common/loading'
import AnimatedIcon from '@components/common/animated_icon'

import { DEFAULT_SHOP_ID } from '@static/constants'
import s from './add_cart_button.module.scss'
import { logEvent } from 'firebase/analytics'

function AddCartButton({
   hasAlternativeQty,
   quantity,
   title,
   productCode,
   minimumOrderQuantity,
   className,
   product,
   withWishlistCheckbox
}) {
   const dispatch = useDispatch()
   const { incGoodsCount } = useShoppingList()

   const choosenAddress = useSelector(state => state.choosenAddress)
   const choosenAddressNP = useSelector(state => state.choosenAddressNP)
   const choosenShopId = useSelector(state => state.choosenShopId)
   const addIndicatorsList = useSelector(state => state.addIndicatorsList)
   const shoppingList = useSelector(state => state.shoppingList)

   const [playCartAnim, setPlayCartAnim] = useState(false)
   const [isAdding, setIsAdding] = useState(false)

   // Flag for the availability of goods in other markets
   const hasAlternative = hasAlternativeQty && !quantity

   const [ReactPixel, setReactPixel] = useState(null)

   //facebook PIXEL
   useEffect(() => {
      import('react-facebook-pixel')
         .then((module) => {
            setReactPixel(module.default)
         })
   }, [])

   const handleClick = () => {
      const analyticsInstance = analytics()
      switch (true) {
      case Boolean(hasAlternative):
         return dispatch(changeModal({
            title: 'В наявності: ',
            inner: <ChangeMarketForm
               productCode={ productCode }
               shopId={ choosenShopId }
            />
         }))
      case Boolean(choosenAddress || choosenAddressNP || DEFAULT_SHOP_ID):
         var pixelProps = {
            currency: 'UAH',
            value: product.discountPrice ? product.discountPrice : product.price,
            content_name: product.title,
            content_type: 'product',
            content_ids: [product.id],
            content_category: `${product.categoryId}`
         }

         dispatch(setAddIndicatorsList([
            ...addIndicatorsList,
            { title, code: productCode, quantity }
         ]))
         setIsAdding(true)
         incGoodsCount(productCode, minimumOrderQuantity)
            .finally(() => {
               setIsAdding(false)
               setPlayCartAnim(true)
            })
         logEvent(analyticsInstance, 'add_to_cart', pixelProps)//firebaseAnalitics
         ReactPixel.track('AddToCart', pixelProps) //facebook PIXEL
         break
      default:
         dispatch(setGoodToAdd(productCode))
      }
   }

   const handleCompleteAnimation = () => {
      setPlayCartAnim(false)
   }

   const renderText = (
      quantity
         ? shoppingList[productCode] ? 'Додати ще' : 'Купити'
         : hasAlternativeQty ? 'Обрати супермаркет' : 'Немає в наявності'
   )


   const renderIcon = (
      isAdding
         ? <Loading size="small" width={ 25 } height={ 25 } color="black" />
         : hasAlternative
            ? <img src="/images/common/location_black.svg" className={ s.locationIcon } alt="" />
            : <AnimatedIcon
               width={ 25 }
               height={ 25 }
               isAnimated={ playCartAnim }
               onAnimationComplete={ handleCompleteAnimation }
               animationData={ cartAnimation }
               className={ s.cart_icon }
            />
   )

   return (
      <button
         className={ clx(s.add_cart_button, 'btn', className) }
         disabled={ !quantity && !hasAlternativeQty }
         onClick={ handleClick }
      >
         {renderIcon}
         {!withWishlistCheckbox && <span className={ s.btn_text }>
            {renderText}
         </span>}
      </button>
   )
}

export default memo(AddCartButton)
