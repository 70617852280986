import { useEffect, useRef, useState } from 'react'

export default function AnimatedIcon({
   isAnimated,
   onAnimationComplete,
   animationData,
   loop,
   autoplay,

   width,
   height,

   onClick,
   isFinished,
   className
}) {

   const ref = useRef(null)

   const [lottie, setLottie] = useState(null)
   const [animation, setAnimation] = useState(null)

   useEffect(() => {
      import('lottie-web').then(Lottie => setLottie(Lottie.default))
   }, [])

   useEffect(() => {
      if (!animation) return

      if (isAnimated) {
         animation.play()
      } else {
         animation.goToAndStop(0, true)
      }
   }, [isAnimated, animation])

   useEffect(() => {
      if (lottie && ref.current) {
         const animation = lottie.loadAnimation(
            {
               container: ref.current,
               renderer: 'svg',
               loop,
               autoplay,
               animationData
            },
            {
               onAnimationComplete,
            }
         )

         setAnimation(animation)

         if (isFinished) {
            animation.goToAndStop(100, true)
         }

         return () => animation.destroy()
      }
   }, [lottie])

   return (
      <span
         ref={ ref }
         onClick={ onClick }
         className={ className }
         style={ { width: width, height: height } }
      />
   )
}

const DEFAULT_WIDTH = 50
const DEFAULT_HEIGHT = 50

AnimatedIcon.defaultProps = {
   onClick: () => {},
   onAnimationComplete: () => {},
   width: DEFAULT_WIDTH,
   height: DEFAULT_HEIGHT,
   loop: false,
   autoplay: false
}
