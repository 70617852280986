import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { changeModal, setProductData, setWasChangedMarket } from '@redux/actions'
import { useSetMarket } from '@functions/hooks'

import HistoryContext from '@components/context/history_context'
import TadaServiceContext from '@components/context/tada_service_context'
import BaseSelect from '../../base_select'
import form_s from '../form.module.scss'
import s from './change_user_form.module.scss'

const Map = dynamic(
   () => import('../../../maps/chose_market_form_map').then((mod) => mod.default),
   {
      ssr: false,
      loading: () => null,
   },
)

export default function ChangeMarketForm({
   productCode = null,
   shopId = null
}) {
   const router = useRouter()
   const dispatch = useDispatch()
   const { history, onNextPage } = useContext(HistoryContext)
   const tadaService = useContext(TadaServiceContext)
   const { setMarket } = useSetMarket()
   const [address, setAddress] = useState()

   const {
      productData,
      choosenAddress,
      choosenCity,
      marketsList,
   } = useSelector(state => state)
   const { choosenCityNP, choosenAddressNP, isNPShown } = useSelector(state => state)

   const alternativeQuantity = productData?.alternativeQuantity || []

   const fetchProductData = async (shopId) => {
      try {
         const res = await tadaService.getProductByCode(productCode, shopId)
         dispatch(setProductData(res))
         return res
      } catch (e) {
         tadaService.logError(e)
      }
   }

   useEffect(() => {
      if (!productCode && !router.query?.code) return
      (() => fetchProductData(shopId))()

      if (address) {
         setAddress([])
      }
   }, [])

   const extendedCity = useMemo(() => marketsList.find(market => market.value === choosenCity), [choosenCity, marketsList])
   const myCityMarkets = extendedCity?.items || []

   const addresses = useMemo(() =>
      myCityMarkets.filter(item => alternativeQuantity.some(i => i.shopId === item.shopId))
   , [alternativeQuantity])

   const saveClick = async e => {
      e.preventDefault()

      dispatch(setWasChangedMarket(true))

      try {
         await setMarket(extendedCity, {
            ...address,
            label: address.value
         }, undefined, choosenCityNP, choosenAddressNP, isNPShown)

         onNextPage((_, nextUrl) => {
            !['popular', 'specials', 'category', 'search'].some(page => nextUrl.includes(page))
               && dispatch(setWasChangedMarket(false))
         })
      } catch (e) {
         console.error(e)
         tadaService.logError(e).catch(console.log)
      }

      if (!history.cur?.includes('good')) {
         router.push(`/products/${productCode}`)
      }

      dispatch(changeModal(null))
   }

   const markerClick = (market) => {
      setAddress(myCityMarkets.find(shops => shops.label === market.label))
   }

   const centeredMap = () => {
      if (address)
         return [address.latitude, address.longitude]
      else
         return [addresses[0]?.latitude, addresses[0]?.longitude].some((el) => el === undefined)
            ? undefined
            : [addresses[0]?.latitude, addresses[0]?.longitude]
   }

   return (
      <form className={ form_s.form }>
         <div className={ s.city }>м. {choosenCity}</div>

         <div style={ { zIndex: 1002, position: 'relative' } }>
            <BaseSelect
               options={ addresses.map((item, index) => ({
                  ...item,
                  label: <SelectItem label={ item.label } qty={ alternativeQuantity[index].quantity } />
               })) }
               customStyles={ {
                  singleValue: {
                     width: '100%',
                     paddingRight: '15px',
                     display: 'flex',
                     justifyContent: 'space-between'
                  }
               } }
               placeholder={ 'Супермаркет...' }
               value={ address }
               onChange={ setAddress }
            />
         </div>

         <div className={ form_s.form_column } style={ { height: 250 } }>
            <Map center={ centeredMap() } markerClick={ markerClick } customMarketsList={ addresses } />
         </div>
         <div className={ form_s.form_center }>
            <button className={ form_s.form_big_btn } onClick={ saveClick } disabled={ !choosenCity || !address }>
               Вибрати
            </button>
         </div>
         <div className={ form_s.form_column }>
            <span className={ form_s.form_item_desc }>
               *Кількість наявного товару може змінюватись залежно від обраного супермаркету.
            </span>
         </div>
      </form>
   )
}

const SelectItem = ({ label, qty }) => (
   <span className={ s.select_item }>
      {label}
      <b>{qty} шт.</b>
   </span>
)
